.input-field {
    display: inline-block;
    border: solid 1px black;
    min-width: 10px;
    max-width: 200px
}

select {
    appearance: none;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
}

.vertical-flex-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.vertical-code-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
}

.code-section {
    min-height: 90vh;
    max-height: 90vh;
    overflow-x: scroll;
    overflow-y: scroll;
    background-color: rgb(245, 242, 240);
}

.view-code-section {
    min-height: 90vh;
    max-height: 90vh;
    overflow-x: auto;
    min-width: 48vw;
    max-width: 48vw;
    background-color: #F0F0F0;
}

/* width */

::-webkit-scrollbar {
    height: 5px;
    /* height of horizontal scrollbar */
    width: 0.2vw;
    /* width of vertical scrollbar */
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.center {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}


/* font for Save File/Run Code text in button */

.css-11qr2p8-MuiButtonBase-root-MuiButton-root {
    font-family: 'Courier New' !important;
}


/* MUI button corner for adding coding blocks */

.MuiButton-root {
    border-radius: 7px !important;
}


/* Save File/Run Code button height */

.module-border-wrap {
    /* max-width: 250px; */
    padding: 1rem;
    position: relative;
    background: rgb(245, 242, 240);
    padding: 3px;
}

.module-border-wrap-black {
    /* max-width: 250px; */
    padding: 1rem;
    position: relative;
    background: #F0F0F0;
    padding: 3px;
}

.hljs {
    /* background: rgb(245, 242, 240); */
}

.body {
    -ms-overflow-style: none;
}

.helpbox {
    background-color: white;
    padding: 1px solid red;
}

.hljs-keyword {
    font-size: 2.5vh;
}

input[type="view"],
select[type="view"],
option[type="view"],
textarea {
    background-color: rgb(245, 242, 240);
    border-color: rgb(245, 242, 240);
    border: 2px solid rgb(245, 242, 240);
    /*
    if I hide, the indentation would not be correct
    */
}

input.for,
input.if,
input.elif,
input.else,
input.return,
input.print,
input.while,
input.def,
input.break,
input.continue,
.lambda,
.in {
    color: rgb(0, 119, 170);
}

.equal {
    color: rgb(154, 110, 58);
}

.token {
    background: inherit !important;
}

.range, .list, .map, .filter, .len, .apostrophe {
    color: rgb(102, 153, 0);
}



.bracket, .colon, .comma, .dot, .curly {
    color: rgb(153, 153, 153);
}

input.comment {
    color: rgb(153, 153, 153);
}

.Collapsible {
    background-color: #00BBFF;
}
.Collapsible__contentInner {
    padding: 10px;
    border: 1px solid #ebebeb;
    border-top: 0;
}
.Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
}
.Collapsible__contentInner p:last-child {
    margin-bottom: 0;
}
.Collapsible__trigger {
    display: block;
    font-weight: 500;
    text-decoration: none;
    position: relative;
    border: 1px solid #ebebeb;
    padding: 10px;
    background: #00ac9d;
    color: white;
}

.Collapsible__trigger:after {
    font-family: 'FontAwesome';
    content: url('./images/angle-small-down.svg');
    font-weight: 900;
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
    content: url('./images/angle-small-left.svg');
}
.Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}
.CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
}
.CustomTriggerCSS--open {
    background-color: darkslateblue;
}
.Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #cbb700;
    color: black;
}

.fa-toggle-on {
    color: #00BBFF;
}


.fa-toggle-off {
    color: #00BBFF;
}

._3HDv1 {
   white-space: nowrap;
   text-align: center;
}

._3HDv1.read_only {
    background-color: #00BBFF;
    color: white;
}
._2iAdU { /* hide logo */
    display: none !important;
}

._2Gsmq, ._3r-ro {
    display: none !important;
}

#_2iAdU { /* # means select element by id */
    padding: 0 !important;
}

.tour-css {
    --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
}

iframe { /* class type, no . */
    height: 100vh;
    width: 50vw;
}